import React, { useEffect, useState } from 'react';
import styles from '../UI/DateRangeSelector.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangeSelector = props => {
    const isOpen = props.isOpen;
    const closeModal = props.closeModal;
    const changeDateHandler = props.changeDateHandler;

    window.onclick = function (event) {
        if (event.target.id === "dateRangeSelector") {
            closeModal();
        }

        if (event.target.id === "filtersPanelWrapperMUG") {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
        }
    };

    document.addEventListener('keydown', function (e) {
        if (e.keyCode === 27) {
            closeModal();
        }
    });

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleStartChange = (date) => {
        setStartDate(date);
    };

    const handleEndChange = (date) => {
        setEndDate(date);
    };

    const highlightDates = (date) => {
        if (startDate && endDate) {
            return date >= startDate && date <= endDate;
        } else {
            return false;
        }
    };

    const setDateRange = (rangeType) => {
        const now = new Date();
        let start, end;

        switch (rangeType) {
            case 'Today':
                start = end = now;
                break;
            case 'Yesterday':
                start = end = new Date(now.setDate(now.getDate() - 1));
                break;
            case 'This Week':
                start = new Date(now.setDate(now.getDate() - now.getDay()));
                end = new Date();
                break;
            case 'Last Week':
                start = new Date(now.setDate(now.getDate() - now.getDay() - 7));
                end = new Date(now.setDate(now.getDate() + 6));
                break;
            case 'This Month':
                start = new Date(now.getFullYear(), now.getMonth(), 1);
                end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                break;
            case 'Last Month':
                start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                end = new Date(now.getFullYear(), now.getMonth(), 0);
                break;
            case 'This Year':
                start = new Date(now.getFullYear(), 0, 1);
                end = new Date(now.getFullYear(), 11, 31);
                break;
            case 'Last Year':
                start = new Date(now.getFullYear() - 1, 0, 1);
                end = new Date(now.getFullYear() - 1, 11, 31);
                break;
            case 'LifeTime':
                start = new Date(2018, 0, 1);
                end = new Date();
                break;
            default:
                break;
        }

        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        changeDateHandler(
            new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))?.toISOString()?.substring(0, 10),
            new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))?.toISOString()?.substring(0, 10)
        );
    }, [startDate, endDate]);

    if (!isOpen) {
        return null;
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="dateRangeSelector">
                    <div className={styles.mainCalendarBox}>
                        <div className={styles.calendarContainer}>
                            <div className={styles.ccLeft}>
                                <div className={styles.eachBtnWrapper} onClick={() => setDateRange('Today')}>
                                    Today
                                </div>
                                <div className={styles.eachBtnWrapper} onClick={() => setDateRange('Yesterday')}>
                                    Yesterday
                                </div>
                                <div className={styles.eachBtnWrapper} onClick={() => setDateRange('This Week')}>
                                    This Week
                                </div>
                                <div className={styles.eachBtnWrapper} onClick={() => setDateRange('Last Week')}>
                                    Last Week
                                </div>
                                <div className={styles.eachBtnWrapper} onClick={() => setDateRange('This Month')}>
                                    This Month
                                </div>
                                <div className={styles.eachBtnWrapper} onClick={() => setDateRange('Last Month')}>
                                    Last Month
                                </div>
                                <div className={styles.eachBtnWrapper} onClick={() => setDateRange('This Year')}>
                                    This Year
                                </div>
                                <div className={styles.eachBtnWrapper} onClick={() => setDateRange('Last Year')}>
                                    Last Year
                                </div>
                                <div className={styles.eachBtnWrapper} onClick={() => setDateRange('LifeTime')}>
                                    LifeTime
                                </div>
                            </div>
                            <div className={styles.ccRight}>
                                <div className={styles.dateHeader}>
                                    <div className={styles.eachDateWrapper}>
                                        <div className={styles.eachCalTitle}>Start Date</div>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleStartChange}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            highlightDates={highlightDates}
                                            inline={true}
                                            calendarClassName={styles.eachCalendar}
                                        />
                                    </div>
                                    <div className={styles.eachDateWrapper}>
                                        <div className={styles.eachCalTitle}>End Date</div>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={handleEndChange}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            highlightDates={highlightDates}
                                            inline={true}
                                            calendarClassName={styles.eachCalendar}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default DateRangeSelector;
