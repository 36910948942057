import { style } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { getExecutiveFullName } from '../../Helper/executivesData'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CandidateApplicationRecords.module.css'

const CandidateApplicationRecords = (props) => {
    const { isOpen, onClose, stylistIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [statusData, setStatusData] = useState([])

    window.onclick = event => event.target.id === "mccarid" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    const getCandidateApplicationRecords = async () => {
        const getCandidateApplicationRecordsResponse = await fetch(BASE_URL_API + "/getCandidateApplicationRecords",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier
                })
            });

        if (!getCandidateApplicationRecordsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCandidateApplicationRecordsRespo = await getCandidateApplicationRecordsResponse.json()
            if (getCandidateApplicationRecordsRespo.status === "success") {
                setStatusData(getCandidateApplicationRecordsRespo.response)
            } else {
                if (getCandidateApplicationRecordsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setStatusData([])
                }
            }
            console.log("this is record : ", getCandidateApplicationRecordsRespo)
        }
    }

    useEffect(() => {
        if (isOpen) {
            getCandidateApplicationRecords()
        }
    }, [isOpen])

    function generateInsights(applications) {
        const insights = [];
        const stats = {
            totalApplied: 0,
            totalRejected: 0,
            totalShortlisted: 0,
            totalHired: 0,
            didNotAppearForInterview: 0,
            interviewsBooked: 0,
        };

        // Ensure applications is an array before processing
        if (Array.isArray(applications)) {
            applications.forEach((app) => {
                // Check if the app object is valid
                if (app && typeof app === "object") {
                    // Increment total applications
                    stats.totalApplied++;

                    // Extract general insights for each application
                    const applicationDetails = {
                        applicationId: app.applicationId || null,
                        stylistName: app.stylist_name || "N/A",
                        companyName: app.company_name || "N/A",
                        appliedOn: app.applied_on || "N/A",
                        appliedStatus: app.applied_status || "Unknown",
                        isRejected: app.is_rejected === "1",
                        isShortlisted: app.is_shortlisted === "1",
                        isHired: app.is_hired === "1",
                        interviewBooked: false,
                        rejectionReason: null,
                        actionRemarks: null,
                    };

                    // Check rejection status
                    if (app.is_rejected === "1") {
                        stats.totalRejected++;
                        const rejectedStatus = Array.isArray(app.statusDetails)
                            ? app.statusDetails.find(
                                (status) => status && status.applicationStatus === "REJECTED"
                            )
                            : null;
                        if (rejectedStatus) {
                            applicationDetails.rejectionReason = rejectedStatus.rejectionSubReason || "No reason provided";
                            applicationDetails.actionRemarks = rejectedStatus.actionRemarks || "No remarks";
                        }
                    }

                    // Check interview booking status
                    const interviewStatus = Array.isArray(app.statusDetails)
                        ? app.statusDetails.find(
                            (status) => status && status.applicationStatus === "INTERVIEW_SLOT_BOOKED"
                        )
                        : null;
                    if (interviewStatus) {
                        stats.interviewsBooked++;
                        applicationDetails.interviewBooked = true;
                    } else {
                        stats.didNotAppearForInterview++;
                    }

                    // Check if shortlisted
                    if (app.is_shortlisted === "1") {
                        stats.totalShortlisted++;
                    }

                    // Check if hired
                    if (app.is_hired === "1") {
                        stats.totalHired++;
                    }

                    // Add each application insight to the insights array
                    insights.push(applicationDetails);
                }
            });
        }

        return {
            insights,
            stats,
        };
    }

    // Example usage
    useEffect(() => {
        if (isOpen) {
            console.log("Hold on, I'm withdrawing stats:")
            console.log(generateInsights(statusData?.applications));
        }
    }, [statusData]);

    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="mccarid">
                    <div className={styles.mainContent}>
                        <div className={styles.mainTitle}>
                            Candidate Application Records
                        </div>
                        <div className={styles.allRecords}>
                            {statusData?.applications?.map((item, index) => (
                                <div key={index} className={styles.eachApplicationWrapper}>
                                    <div className={styles.eawUpper}>
                                        <div className={styles.appliedOn}>
                                            {item?.applied_on?.substring(0, 10)}
                                        </div>
                                        <div className={styles.companyName}>
                                            {item?.company_name}
                                        </div>
                                    </div>
                                    <div className={styles.eawLower}>
                                        <div className={styles.appliedOn}>
                                            <div className={styles.appliedBy}>
                                                Applied by {getExecutiveFullName(item?.applicationCreatingExecutiveIdentifier)}
                                            </div>
                                            <div className={styles.appliedOnTime}>
                                                {humanReadableTimePassed(item?.applied_on)}
                                            </div>
                                        </div>
                                        <div className={styles.statusWrapper}>
                                            {JSON.parse(item?.statusDetails)?.map((itemStatus, indexStatus) => (
                                                itemStatus?.applicationStatus !== "APPLIED" &&
                                                <div key={indexStatus} className={styles.eachItemWrapper}>
                                                    <div className={styles.itemStatus}>
                                                        <div className={styles.updatedOn}>
                                                            Status updated on {itemStatus?.updatedOn}
                                                        </div>
                                                    </div>
                                                    <div className={styles.entireItem}>
                                                        {itemStatus?.applicationStatus === "INTERVIEW_SLOT_BOOKED" ?
                                                            <div className={styles.timeSlot}>
                                                                Slot booked for {itemStatus?.timeSlot}
                                                            </div>
                                                            :
                                                            <div className={styles.timeSlot}>
                                                                {itemStatus?.applicationStatus}
                                                            </div>
                                                        }
                                                        {itemStatus?.slotRemarks?.trim()?.length > 0 &&
                                                            <div className={styles.slotRemarks}>
                                                                {itemStatus?.slotRemarks}
                                                            </div>
                                                        }
                                                        <div className={styles.actionStatus}>
                                                            {itemStatus?.actionStatus}
                                                        </div>
                                                        <div className={styles.rejectionSubReason}>
                                                            {itemStatus?.rejectionSubReason}
                                                        </div>
                                                        {itemStatus?.actionRemarks?.trim()?.length > 0 &&
                                                            <div className={styles.actionRemarks}>
                                                                {itemStatus?.actionRemarks}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CandidateApplicationRecords