import React, { useEffect, useRef, useState } from 'react';
import { FaCheckSquare, FaSquare } from 'react-icons/fa';
import styles from './MultiSelectDropdown.module.css';

const MultiSelectDropdown = (props) => {
    const { options, selectedOptions, onSelect, placeholder } = props;
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const handleOptionClick = (option) => {
        const newSelection = selectedOptions.includes(option)
            ? selectedOptions.filter((opt) => opt !== option)
            : [...selectedOptions, option];

        onSelect(newSelection); // Pass the new selection back to the parent
    };

    // Toggles dropdown visibility
    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    // Handle outside click to close the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.multiSelectDropdown}>
            {/* Placeholder that toggles the dropdown */}
            <div className={styles.placeholder} onClick={toggleDropdown}>
                {selectedOptions.length === 0 ? (
                    <span>{placeholder}</span>
                ) : (
                    <span>{`${selectedOptions?.length} option(s) selected`}</span>
                )}
            </div>

            {/* Dropdown list */}
            {isDropdownVisible && (
                <ul className={styles.optionsList} ref={dropdownRef}>
                    {options.map((option) => (
                        <li
                            key={option.userID}
                            className={selectedOptions.includes(option) ? 'selected' : ''}
                            onClick={() => handleOptionClick(option)}
                        >
                            {selectedOptions.includes(option) ? (
                                <FaCheckSquare className={styles.checkboxIcon} />
                            ) : (
                                <FaSquare className={styles.checkboxIcon} />
                            )}
                            {option.firstName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
