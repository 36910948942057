import { style } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5'
import { getTimeDifferenceHumanReadable, humanReadableTimePassed } from '../../../Helper/helpers'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import AllStoreConversations from '../../Conversations/AllStoreConversations'
import JTApplicationsData from './JTApplicationsData'
import styles from './JTEachRow.module.css'
import { IoIosCreate } from "react-icons/io";
import { getExecutiveFullName } from '../../../Helper/executivesData'


const JTEachRow = ({ item }) => {
    const authCtx = useContext(AuthContext)
    const [sortBy, setSortBy] = useState('DISTANCE_NEAREST_FIRST')
    const [minAge, setMinAge] = useState(10)
    const [maxAge, setMaxAge] = useState(44)
    const [minSalary, setMinSalary] = useState(5000)
    const [maxSalary, setMaxSalary] = useState(65000)

    const [isScHolderOpen, setIsScHolderOpen] = useState(false)
    const onClosingScHolder = () => setIsScHolderOpen(false)


    const [profilesData, setProfilesData] = useState([])
    const updateProfileDataHandler = k => setProfilesData(k)

    const [densityData, setDensityData] = useState([])
    const [isPopulatingDensity, setIsPopulatingDensity] = useState(false)
    const [estimatedData, setEstimatedData] = useState([])
    const [hasPopulatedTheData, setHasPopulatedTheData] = useState(false)

    const [applicationsData, setApplicationsData] = useState([])
    const [isGettingAppData, setIsGettingAppData] = useState(false)
    const [appDataVisible, setAppDataVisible] = useState(false)


    // estimateCandidatesDensityForAJob
    const estimateCandidatesDensityForAJob = async () => {
        setIsPopulatingDensity(true)
        const estimateCandidatesDensityForAJobResponse = await fetch(BASE_URL_API + "/estimateCandidatesDensityForAJob",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: item?.jobDetails?.job_id,
                    sortBy: sortBy,
                    minAge: minAge,
                    maxAge: maxAge,
                    minSalary: minSalary,
                    maxSalary: maxSalary
                })
            });

        if (!estimateCandidatesDensityForAJobResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const estimateCandidatesDensityForAJobRespo = await estimateCandidatesDensityForAJobResponse.json();
            if (estimateCandidatesDensityForAJobRespo.status === 'success') {
                if (estimateCandidatesDensityForAJobRespo.response !== null) {
                    updateProfileDataHandler(estimateCandidatesDensityForAJobRespo.response)

                } else {
                    updateProfileDataHandler([]);
                }
            } else {
                if (estimateCandidatesDensityForAJobRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log(estimateCandidatesDensityForAJobRespo)
                }
            }
            setHasPopulatedTheData(true)
            console.log(estimateCandidatesDensityForAJobRespo)
        }
        setIsPopulatingDensity(false)
    }

    const filterEstimatedResults = () => {
        const resBuilder = []
        resBuilder["Within 5 kms"] = profilesData.filter((item) => parseFloat(item?.distance) < 5).length
        resBuilder["Within 10 kms"] = profilesData.filter((item) => parseFloat(item?.distance) < 10).length
        resBuilder["Within 15 kms"] = profilesData.filter((item) => parseFloat(item?.distance) < 15).length
        resBuilder["Within 20 kms"] = profilesData.filter((item) => parseFloat(item?.distance) < 20).length
        resBuilder["Within 30 kms"] = profilesData.filter((item) => parseFloat(item?.distance) < 30).length
        resBuilder["Within 40 kms"] = profilesData.filter((item) => parseFloat(item?.distance) < 40).length
        resBuilder["Within 50 kms"] = profilesData.filter((item) => parseFloat(item?.distance) < 50).length
        resBuilder["Within 80 kms"] = profilesData.filter((item) => parseFloat(item?.distance) < 80).length
        resBuilder["Within 100 kms"] = profilesData.filter((item) => parseFloat(item?.distance) < 100).length

        setEstimatedData(resBuilder)
    }

    useEffect(() => {
        filterEstimatedResults()
    }, [densityData, hasPopulatedTheData])


    const getApplicationsSummaryForJob = async () => {
        setIsGettingAppData(true)
        const getApplicationsSummaryForJobResponse = await fetch(BASE_URL_API + "/getApplicationsSummaryForJob",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: item?.jobDetails?.job_id
                })
            });

        if (!getApplicationsSummaryForJobResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getApplicationsSummaryForJobRespo = await getApplicationsSummaryForJobResponse.json()
            if (getApplicationsSummaryForJobRespo.status === "success") {
                setApplicationsData(getApplicationsSummaryForJobRespo.response)
            } else {
                if (getApplicationsSummaryForJobRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setApplicationsData([])
                }
            }
            console.log(getApplicationsSummaryForJobRespo)
        }
        setIsGettingAppData(false)
    }

    const viewApplicationHandler = (k) => setAppDataVisible(k)



    // console.log(estimatedData["Within 10 kms"])
    // console.log(estimatedData)


    return (
        <div className={`${styles.eachJobRow} ${item?.jobDetails?.isPaidHiring === "1" ? styles.paid : ''}`}>
            {isScHolderOpen && <AllStoreConversations 
                isOpen={isScHolderOpen} 
                storeIdentifier={item?.jobDetails?.store_id} 
                onClose={onClosingScHolder} 
                jobIdentifier={item?.jobDetails?.job_id}
            />}
            <JTApplicationsData
                isOpen={appDataVisible}
                viewApplicationHandler={viewApplicationHandler}
                jobIdentifier={item?.jobDetails?.job_id}
            />
            <div className={styles.postedOn} title={item?.jobDetails?.creation_date}>
                {humanReadableTimePassed(item?.jobDetails?.creation_date)}
                {/* {item?.jobDetails?.job_id} */}
            </div>
            <div className={styles.title}>
                {item?.jobDetails?.title}
            </div>
            <div className={styles.storeDetails}>
                <a target="_blank" href={`/manage-stores/${item?.jobDetails?.store_id}`} className={styles.company_name}>
                    {item?.jobDetails?.company_name}
                </a>
                <div className={styles.address}>
                    {item?.jobDetails?.address}
                </div>
            </div>
            <div className={styles.allocationData}>
                {item?.allotedData?.map((itemAlloted, indexAlloted) => (
                    <div key={indexAlloted} className={styles.eachAllocationItem}>
                        <div title={itemAlloted?.allotedTo}>
                            {itemAlloted?.firstName !== null ?
                                <span className={styles.executiveName}>
                                    {getExecutiveFullName(itemAlloted?.allotedTo)}
                                </span>
                                :
                                <span className={styles.currentlyUnalloted}>
                                    Currently Un-alloted!
                                </span>
                            }
                        </div>
                        <div className={styles.allocationTimeFrame}>
                            {getTimeDifferenceHumanReadable(item?.jobDetails?.creation_date, itemAlloted?.allotedOn)}
                        </div>
                    </div>

                ))}
            </div>
            <div className={styles.currentStatus}>
                {item?.jobDetails?.isClosed === '1' ?
                    <div>
                        <div>
                            <div className={styles.closedOn}>
                                {item?.jobDetails?.closedOn}
                            </div>
                            <div className={styles.timeDiff}>
                                {getTimeDifferenceHumanReadable(item?.jobDetails?.creation_date, item?.jobDetails?.closedOn)}
                            </div>
                        </div>
                        <div>
                            <div className={styles.reasonOfClosing}>
                                {item?.jobDetails?.reasonOfClosing}
                            </div>
                            <div>
                                {item?.jobDetails?.closingNotes}
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        {item?.jobDetails?.currentStatus}
                        <div>
                        {humanReadableTimePassed(item?.jobDetails?.lastStatusUpdatedOn) }
                        </div>
                    </div>
                }
            </div>
            <div className={styles.densityWrapper}>
                {isPopulatingDensity === true ?
                    <div>Populating Data...</div>
                    :
                    <button onClick={() => estimateCandidatesDensityForAJob()} className={style.getDensityBtn} >Get</button>
                }

                <div>
                    {hasPopulatedTheData === true &&
                        <div className={styles.distanceWrapper}>
                            <div className={styles.eachDistance}>
                                <div className={styles.distanceDataCaption}> In 5 kms</div>
                                <div className={styles.distanceResult}>
                                    {estimatedData["Within 5 kms"]}
                                </div>
                            </div>

                            <div className={styles.eachDistance}>
                                <div className={styles.distanceDataCaption}> In 10 kms</div>
                                <div className={styles.distanceResult}>
                                    {estimatedData["Within 10 kms"]}
                                </div>
                            </div>

                            <div className={styles.eachDistance}>
                                <div className={styles.distanceDataCaption}> In 15 kms</div>
                                <div className={styles.distanceResult}>
                                    {estimatedData["Within 15 kms"]}
                                </div>
                            </div>

                            <div className={styles.eachDistance}>
                                <div className={styles.distanceDataCaption}> In 20 kms</div>
                                <div className={styles.distanceResult}>
                                    {estimatedData["Within 20 kms"]}
                                </div>
                            </div>

                            <div className={styles.eachDistance}>
                                <div className={styles.distanceDataCaption}> In 30 kms</div>
                                <div className={styles.distanceResult}>
                                    {estimatedData["Within 30 kms"]}
                                </div>
                            </div>

                            <div className={styles.eachDistance}>
                                <div className={styles.distanceDataCaption}> In 40 kms</div>
                                <div className={styles.distanceResult}>
                                    {estimatedData["Within 40 kms"]}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.applicationsData} >
                {isGettingAppData === false ?
                    <button onClick={() => getApplicationsSummaryForJob()}>Get Applications Data</button>
                    :
                    <span>Getting applications data...</span>
                }

                <div>
                    {applicationsData?.map((item, index) => (
                        <div className={styles.applicationStats}>
                            <div>
                                {item?.totalApplications}
                            </div>
                            <div>
                                {item?.applied_source}
                            </div>
                        </div>
                    ))}

                    <div>
                        <button onClick={() => viewApplicationHandler(true)}>View Applications</button>
                    </div>
                </div>
            </div>
            <div className={styles.actionWrapper}>
                <button style={{ backgroundColor: '#A89BF1', border: 'none', borderRadius: '4px', height: '40px', width: '40px' }}
                    onClick={() => setIsScHolderOpen(true)}
                    title="Open all conversation related to this store"
                >
                    <IoChatbubbleEllipsesSharp size={20} color="white" />
                </button>
                <a target="_blank" href={`manage-job/${item?.jobDetails?.job_id}`} title="View Job"

                >
                    <FaEye size={20} color="white" />
                </a>
                <a target="_blank" href={`create-applications/${item?.jobDetails?.job_id}`}
                    title="Create Applications"
                >
                    <IoIosCreate size={20} color="white" />
                </a>
            </div>

        </div>
    )
}

export default JTEachRow