import React, { useContext, useEffect, useState } from 'react'
import { MdLogout } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import PSInterviewScheduled from '../../Components/PerformanceSummary/PSInterviewScheduled'
import PSPlacementsDone from '../../Components/PerformanceSummary/PSPlacementsDone'
import PSProfilesCreated from '../../Components/PerformanceSummary/PSProfilesCreated'
import PSResumeSold from '../../Components/PerformanceSummary/PSResumeSold'
import PSRevenueContribution from '../../Components/PerformanceSummary/PSRevenueContribution'
import { getDateRange } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../PerformanceSummary/ExecutiveSummary.module.css'

const ExecutiveSummary = () => {
    const authCtx = useContext(AuthContext)
    const [allExe, setAllExe] = useState([])
    const [ei, setEi] = useState(authCtx.userIdentifier)
    const [datePeriod, setDatePeriod] = useState('Today')
    const [intervalForPlot, setIntervalForPlot] = useState('HOURLY')
    const [activeParameter, setActiveParameter] = useState('REVENUE') //PLACEMENTS, INTERVIEWS, PROFILES, RESUME 

    const adminGetAllExecutives = async () => {
        const adminGetAllExecutivesResponse = await fetch(BASE_URL_API + "/adminGetAllExecutives",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!adminGetAllExecutivesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetAllExecutivesRespo = await adminGetAllExecutivesResponse.json();
            if (adminGetAllExecutivesRespo.status === 'success') {
                setAllExe(adminGetAllExecutivesRespo.response)
            } else {
                if (adminGetAllExecutivesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllExe([])
                }
            }
        }

    }

    useEffect(() => {
        adminGetAllExecutives()
    }, [])


    const [summaryData, setSummaryData] = useState(null)
    const [trendsData, setTrendsData] = useState(null)

    const getInterviewScheduledSummary = async () => {
        const getInterviewScheduledSummaryResponse = await fetch(BASE_URL_API + "/getInterviewScheduledSummary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: ei,
                    timeFrame: datePeriod
                })
            });

        if (!getInterviewScheduledSummaryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getInterviewScheduledSummaryRespo = await getInterviewScheduledSummaryResponse.json()
            if (getInterviewScheduledSummaryRespo.status === "success") {
                setSummaryData(getInterviewScheduledSummaryRespo?.response)
            } else {
                if (getInterviewScheduledSummaryRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setSummaryData(null)
                }
            }
            console.log(getInterviewScheduledSummaryRespo)
        }

    }

    const getResumeSoldSummary = async () => {
        const getResumeSoldSummaryResponse = await fetch(BASE_URL_API + "/getResumeSoldSummary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: ei,
                    timeFrame: datePeriod
                })
            });

        if (!getResumeSoldSummaryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getResumeSoldSummaryRespo = await getResumeSoldSummaryResponse.json()
            if (getResumeSoldSummaryRespo.status === "success") {
                setSummaryData(getResumeSoldSummaryRespo?.response)
            } else {
                if (getResumeSoldSummaryRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setSummaryData(null)
                }
            }
            console.log(getResumeSoldSummaryRespo)
        }

    }

    const getPlacementsDoneSummary = async () => {
        const getPlacementsDoneSummaryResponse = await fetch(BASE_URL_API + "/getPlacementsDoneSummary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: ei,
                    timeFrame: datePeriod
                })
            });

        if (!getPlacementsDoneSummaryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPlacementsDoneSummaryRespo = await getPlacementsDoneSummaryResponse.json()
            if (getPlacementsDoneSummaryRespo.status === "success") {
                setSummaryData(getPlacementsDoneSummaryRespo?.response)
            } else {
                if (getPlacementsDoneSummaryRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setSummaryData(null)
                }
            }
            console.log(getPlacementsDoneSummaryRespo)
        }

    }

    const getProfilesCreatedSummary = async () => {
        const getProfilesCreatedSummaryResponse = await fetch(BASE_URL_API + "/getProfilesCreatedSummary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: ei,
                    timeFrame: datePeriod
                })
            });

        if (!getProfilesCreatedSummaryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getProfilesCreatedSummaryRespo = await getProfilesCreatedSummaryResponse.json()
            if (getProfilesCreatedSummaryRespo.status === "success") {
                setSummaryData(getProfilesCreatedSummaryRespo?.response)
            } else {
                if (getProfilesCreatedSummaryRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setSummaryData(null)
                }
            }
            console.log(getProfilesCreatedSummaryRespo)
        }

    }

    const getInterviewScheduledTrends = async () => {
        console.log("getting trends...")
        const getInterviewScheduledTrendsResponse = await fetch(BASE_URL_API + "/getInterviewScheduledTrends",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: ei,
                    interval: intervalForPlot
                })
            });

        if (!getInterviewScheduledTrendsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getInterviewScheduledTrendsRespo = await getInterviewScheduledTrendsResponse.json()
            if (getInterviewScheduledTrendsRespo.status === "success") {
                setTrendsData(getInterviewScheduledTrendsRespo?.response)
            } else {
                if (getInterviewScheduledTrendsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setTrendsData(null)
                }
            }
            console.log(getInterviewScheduledTrendsRespo)
        }

    }

    const getResumeSoldTrends = async () => {
        console.log("getting trends...")
        const getResumeSoldTrendsResponse = await fetch(BASE_URL_API + "/getResumeSoldTrends",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: ei,
                    interval: intervalForPlot
                })
            });

        if (!getResumeSoldTrendsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getResumeSoldTrendsRespo = await getResumeSoldTrendsResponse.json()
            if (getResumeSoldTrendsRespo.status === "success") {
                setTrendsData(getResumeSoldTrendsRespo?.response)
            } else {
                if (getResumeSoldTrendsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setTrendsData(null)
                }
            }
            console.log(getResumeSoldTrendsRespo)
        }

    }

    const getPlacementsDoneTrends = async () => {
        console.log("getting trends...")
        const getPlacementsDoneTrendsResponse = await fetch(BASE_URL_API + "/getPlacementsDoneTrends",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: ei,
                    interval: intervalForPlot
                })
            });

        if (!getPlacementsDoneTrendsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPlacementsDoneTrendsRespo = await getPlacementsDoneTrendsResponse.json()
            if (getPlacementsDoneTrendsRespo.status === "success") {
                setTrendsData(getPlacementsDoneTrendsRespo?.response)
            } else {
                if (getPlacementsDoneTrendsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setTrendsData(null)
                }
            }
            console.log(getPlacementsDoneTrendsRespo)
        }

    }

    const getProfilesCreatedTrends = async () => {
        console.log("getting trends...")
        const getProfilesCreatedTrendsResponse = await fetch(BASE_URL_API + "/getProfilesCreatedTrends",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: ei,
                    interval: intervalForPlot
                })
            });

        if (!getProfilesCreatedTrendsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getProfilesCreatedTrendsRespo = await getProfilesCreatedTrendsResponse.json()
            if (getProfilesCreatedTrendsRespo.status === "success") {
                setTrendsData(getProfilesCreatedTrendsRespo?.response)
            } else {
                if (getProfilesCreatedTrendsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setTrendsData(null)
                }
            }
            console.log(getProfilesCreatedTrendsRespo)
        }

    }

    useEffect(() => {
        if (activeParameter === "INTERVIEWS") {
            getInterviewScheduledSummary()
        }
        if (activeParameter === "RESUMES") {
            getResumeSoldSummary()
        }
        if (activeParameter === "PLACEMENTS") {
            getPlacementsDoneSummary()
        }
        if (activeParameter === "PROFILES") {
            getProfilesCreatedSummary()
        }
    }, [ei, datePeriod, activeParameter])


    useEffect(() => {
        if (activeParameter === "INTERVIEWS") {
            getInterviewScheduledTrends()
        }
        if (activeParameter === "RESUMES") {
            getResumeSoldTrends()
        }
        if (activeParameter === "PLACEMENTS") {
            getPlacementsDoneTrends()
        }
        if (activeParameter === "PROFILES") {
            getProfilesCreatedTrends()
        }
    }, [ei, intervalForPlot, activeParameter])

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.header}>
                <div className={styles.headerNavigation}>
                    <Link to="/">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                    <Link to="/tasks">
                        <div className={styles.eachHeaderNavigation}>
                            Tasks
                        </div>
                    </Link>
                </div>
                <div>
                    <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                        <MdLogout />
                    </button>
                </div>
            </div>
            <div className={styles.optionContainer}>
                <div className={styles.actionWrapper}>
                    <div className={styles.executiveWrapper}>
                        {authCtx.userIdentifier === "999964N8406" ?
                            <select value={ei} onChange={(e) => setEi(e.target.value)} >
                                <option>Select Executive </option>
                                {allExe?.map((item, index) => (
                                    <option key={index} value={item.userID}>{item.firstName} {item.middleName} {item.lastName}</option>
                                ))}
                            </select>
                            :
                            <div>
                                {allExe.filter((item) => item.userID === authCtx.userIdentifier).map((i, index) => i.firstName + ` ` + i.middleName + ` ` + i.lastName)}
                            </div>
                        }
                    </div>
                    <div className={styles.actionContainer}>
                        {/* <button className={activeParameter === 'REVENUE' ? styles.activeAction : styles.action} onClick={() => setActiveParameter("REVENUE")} >Revenue Contribution</button> */}
                        <button className={activeParameter === 'PLACEMENTS' ? styles.activeAction : styles.action} onClick={() => setActiveParameter("PLACEMENTS")} >Placements Done</button>
                        <button className={activeParameter === 'RESUMES' ? styles.activeAction : styles.action} onClick={() => setActiveParameter("RESUMES")} >Resume Built</button>
                        <button className={activeParameter === 'INTERVIEWS' ? styles.activeAction : styles.action} onClick={() => setActiveParameter("INTERVIEWS")} >Interview Scheduled</button>
                        <button className={activeParameter === 'PROFILES' ? styles.activeAction : styles.action} onClick={() => setActiveParameter("PROFILES")} >Profiles Created</button>
                    </div>
                </div>

                <div className={styles.leftBodyWrapper}>
                    <div className={styles.leftBodyHeader}>
                        <div className={styles.messageTitle}>
                            You performance summary is live now....
                        </div>
                    </div>

                    <div>
                        {/* {activeParameter === "INTERVIEWS" && <PSInterviewScheduled ei={ei} intervalForPlot={intervalForPlot} />}
                        {activeParameter === "PLACEMENTS" && <PSPlacementsDone ei={ei} intervalForPlot={intervalForPlot} />}
                        {activeParameter === "PROFILES" && <PSProfilesCreated ei={ei} intervalForPlot={intervalForPlot} />}
                        {activeParameter === "RESUMES" && <PSResumeSold ei={ei} intervalForPlot={intervalForPlot} />}
                        {activeParameter === "REVENUE" && <PSRevenueContribution ei={ei} intervalForPlot={intervalForPlot} />} */}

                        {activeParameter === "INTERVIEWS" &&
                            <div className={styles.plotMainWrapper}>
                                <div className={styles.summaryWrapper}>
                                    <div className={styles.summaryHeader}>
                                        <div className={styles.titleTxt}>{activeParameter} Scheduled Summary</div>
                                        <div>
                                            <div>
                                                <select onChange={(e) => setDatePeriod(e.target.value)} value={datePeriod}>
                                                    <option>Today</option>
                                                    <option>Yesterday</option>
                                                    <option>This Week</option>
                                                    <option>Last Week</option>
                                                    <option>This Month</option>
                                                    <option>Last Month</option>
                                                    <option>This Year</option>
                                                    <option>Last Year</option>
                                                    <option>Lifetime</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={styles.cardsWrapper}>
                                            <div className={styles.appliedCount}>
                                                {summaryData?.length}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.trendsWrapper}>
                                    <div className={styles.trendsHeader}>
                                        <div className={styles.titleTxt}>Scheduled Interview Trends</div>
                                        <div>
                                            <div className={styles.intervalActionsContainer}>
                                                <button onClick={() => setIntervalForPlot('HOURLY')}
                                                    className={intervalForPlot === 'HOURLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    HOURLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('DAILY')}
                                                    className={intervalForPlot === 'DAILY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    DAILY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('WEEKLY')}
                                                    className={intervalForPlot === 'WEEKLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    WEEKLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('MONTHLY')}
                                                    className={intervalForPlot === 'MONTHLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    MONTHLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('ANNUALLY')}
                                                    className={intervalForPlot === 'ANNUALLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    ANNUALLY
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.graphWrapper}>
                                        <ResponsiveContainer width="100%" height={350}>
                                            <LineChart width="100%" height={350} data={trendsData}
                                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="timeInterval" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Line type="monotone" dataKey="count" stroke="#8884d8" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        }
                        {activeParameter === "PLACEMENTS" &&
                            <div className={styles.plotMainWrapper}>
                                <div className={styles.summaryWrapper}>
                                    <div className={styles.summaryHeader}>
                                        <div className={styles.titleTxt}>Placements Done Summary</div>
                                        <div>
                                            <div>
                                                <select onChange={(e) => setDatePeriod(e.target.value)} value={datePeriod}>
                                                    <option>Today</option>
                                                    <option>Yesterday</option>
                                                    <option>This Week</option>
                                                    <option>Last Week</option>
                                                    <option>This Month</option>
                                                    <option>Last Month</option>
                                                    <option>This Year</option>
                                                    <option>Last Year</option>
                                                    <option>Lifetime</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={styles.cardsWrapper}>
                                            <div className={styles.appliedCount}>
                                                {summaryData?.length}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.trendsWrapper}>
                                    <div className={styles.trendsHeader}>
                                        <div className={styles.titleTxt}>Placements Done Trends</div>
                                        <div>
                                            <div className={styles.intervalActionsContainer}>
                                                <button onClick={() => setIntervalForPlot('HOURLY')}
                                                    className={intervalForPlot === 'HOURLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    HOURLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('DAILY')}
                                                    className={intervalForPlot === 'DAILY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    DAILY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('WEEKLY')}
                                                    className={intervalForPlot === 'WEEKLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    WEEKLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('MONTHLY')}
                                                    className={intervalForPlot === 'MONTHLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    MONTHLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('ANNUALLY')}
                                                    className={intervalForPlot === 'ANNUALLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    ANNUALLY
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.graphWrapper}>
                                        <ResponsiveContainer width="100%" height={350}>
                                            <LineChart width="100%" height={350} data={trendsData}
                                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="timeInterval" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Line type="monotone" dataKey="count" stroke="#8884d8" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        }
                        {activeParameter === "PROFILES" &&
                            <div className={styles.plotMainWrapper}>
                                <div className={styles.summaryWrapper}>
                                    <div className={styles.summaryHeader}>
                                        <div className={styles.titleTxt}>Profiles Created Summary</div>
                                        <div>
                                            <div>
                                                <select onChange={(e) => setDatePeriod(e.target.value)} value={datePeriod}>
                                                    <option>Today</option>
                                                    <option>Yesterday</option>
                                                    <option>This Week</option>
                                                    <option>Last Week</option>
                                                    <option>This Month</option>
                                                    <option>Last Month</option>
                                                    <option>This Year</option>
                                                    <option>Last Year</option>
                                                    <option>Lifetime</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={styles.cardsWrapper}>
                                            <div className={styles.appliedCount}>
                                                {summaryData?.length}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.trendsWrapper}>
                                    <div className={styles.trendsHeader}>
                                        <div className={styles.titleTxt}>Profiles Created Trends</div>
                                        <div>
                                            <div className={styles.intervalActionsContainer}>
                                                <button onClick={() => setIntervalForPlot('HOURLY')}
                                                    className={intervalForPlot === 'HOURLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    HOURLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('DAILY')}
                                                    className={intervalForPlot === 'DAILY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    DAILY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('WEEKLY')}
                                                    className={intervalForPlot === 'WEEKLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    WEEKLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('MONTHLY')}
                                                    className={intervalForPlot === 'MONTHLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    MONTHLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('ANNUALLY')}
                                                    className={intervalForPlot === 'ANNUALLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    ANNUALLY
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.graphWrapper}>
                                        <ResponsiveContainer width="100%" height={350}>
                                            <LineChart width="100%" height={350} data={trendsData}
                                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="timeInterval" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Line type="monotone" dataKey="count" stroke="#8884d8" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        }
                        {activeParameter === "RESUMES" &&
                            <div className={styles.plotMainWrapper}>
                                <div className={styles.summaryWrapper}>
                                    <div className={styles.summaryHeader}>
                                        <div className={styles.titleTxt}>Resume Sold Summary</div>
                                        <div>
                                            <div>
                                                <select onChange={(e) => setDatePeriod(e.target.value)} value={datePeriod}>
                                                    <option>Today</option>
                                                    <option>Yesterday</option>
                                                    <option>This Week</option>
                                                    <option>Last Week</option>
                                                    <option>This Month</option>
                                                    <option>Last Month</option>
                                                    <option>This Year</option>
                                                    <option>Last Year</option>
                                                    <option>Lifetime</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={styles.cardsWrapper}>
                                            <div className={styles.appliedCount}>
                                                {summaryData?.length}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.trendsWrapper}>
                                    <div className={styles.trendsHeader}>
                                        <div className={styles.titleTxt}>Scheduled Interview Trends</div>
                                        <div>
                                            <div className={styles.intervalActionsContainer}>
                                                <button onClick={() => setIntervalForPlot('HOURLY')}
                                                    className={intervalForPlot === 'HOURLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    HOURLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('DAILY')}
                                                    className={intervalForPlot === 'DAILY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    DAILY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('WEEKLY')}
                                                    className={intervalForPlot === 'WEEKLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    WEEKLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('MONTHLY')}
                                                    className={intervalForPlot === 'MONTHLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    MONTHLY
                                                </button>
                                                <button onClick={() => setIntervalForPlot('ANNUALLY')}
                                                    className={intervalForPlot === 'ANNUALLY' ? styles.activePlotInterval : styles.plotInterval}
                                                >
                                                    ANNUALLY
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.graphWrapper}>
                                        <ResponsiveContainer width="100%" height={350}>
                                            <LineChart width="100%" height={350} data={trendsData}
                                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="timeInterval" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Line type="monotone" dataKey="count" stroke="#8884d8" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        }
                        {activeParameter === "REVENUE" && <PSRevenueContribution ei={ei} intervalForPlot={intervalForPlot} />}
                    </div>
                </div>
                <div className={styles.rightBodyWrapper}>
                    {activeParameter === "INTERVIEWS" &&
                        <div>
                            <div className={styles.secTitle}>Interview Scheduled</div>
                            <div className={styles.allInterviewsWrapper} >
                                {summaryData?.map((item, index) => (
                                    <a href={`/manage-application/${item?.applicationId}`} target="_blank" key={index} className={styles.eachApplicationTile}>
                                        <div className={styles.stylistName}>
                                            {item?.stylistName}
                                        </div>
                                        <div className={styles.stylistContact}>
                                            {item?.stylistContact}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    }
                  
                    {activeParameter === "RESUMES" &&
                        <div>
                            <div className={styles.secTitle}>Resume Sold</div>
                            <div className={styles.allInterviewsWrapper} >
                                {summaryData?.map((item, index) => (
                                    <a href={`/manage-users/grow/${item?.stylistIdentifier}`} target="_blank" key={index} className={styles.eachApplicationTile}>
                                        <div className={styles.stylistName}>
                                            {item?.stylistName}
                                        </div>
                                        <div className={styles.stylistContact}>
                                            {item?.stylistContact}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    }
                   
                    {activeParameter === "PLACEMENTS" &&
                        <div>
                            <div className={styles.secTitle}>Placements Done</div>
                            <div className={styles.allInterviewsWrapper} >
                                {summaryData?.map((item, index) => (
                                    <a href={`/manage-application/${item?.applicationId}`} target="_blank" key={index} className={styles.eachApplicationTile}>
                                        <div className={styles.stylistName}>
                                            {item?.stylistName}
                                        </div>
                                        <div className={styles.stylistContact}>
                                            {item?.stylistContact}
                                        </div>
                                        <div className={styles.storeName}>
                                            {item?.storeName}
                                        </div>
                                        <div className={styles.storeAddress}>
                                            {item?.storeAddress}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    }

                    {activeParameter === "PROFILES" &&
                        <div>
                            <div className={styles.secTitle}>Profiles Created</div>
                            <div className={styles.allInterviewsWrapper} >
                                {summaryData?.map((item, index) => (
                                    <a href={`/manage-users/grow/${item?.stylistIdentifier}`} target="_blank" key={index} className={styles.eachApplicationTile}>
                                        <div className={styles.stylistName}>
                                            {item?.stylistName}
                                        </div>
                                        <div className={styles.stylistContact}>
                                            {item?.stylistContact}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    }
                    

                </div>
            </div>
        </div>
    )
}

export default ExecutiveSummary