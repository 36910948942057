import React, { useContext, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { isValidMobileNumber, validatePhoneNumber } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './FTAddStudents.module.css'

const FTAddStudents = (props) => {
    const { isOpen, toggleAddStudents, onSuccess } = props
    const { driveIdentifier } = useParams()
    const [rawData, setRawData] = useState('')
    const [scannedNumber, setScannedNumber] = useState([])
    const [sentNumber, setSentNumber] = useState([])
    const authCtx = useContext(AuthContext)
    const [isAdding, setIsAdding] = useState(false)


    const readDataHandler = () => {
        console.log(rawData)
        console.log(rawData?.split(`\n`))
        let tempArr = []
        let tempData = rawData.trim()
        tempData?.split(`\n`).forEach(element => {
            if (element !== '') {
                if (validatePhoneNumber(element)) {
                    let item = { phoneNumber: element, isNumberValid: true, fullName: null, stylistIdentifier: null, hasRegisteredOnPortal: null, hasRegisteredForDrive: null }
                    tempArr.push(item)
                } else {
                    let item = { phoneNumber: element, isNumberValid: false, fullName: null, stylistIdentifier: null, hasRegisteredOnPortal: null, hasRegisteredForDrive: null }
                    tempArr.push(item)
                }
                console.log(isValidMobileNumber(element))
            } else {
                console.log("blank number")
            }
        });
        setScannedNumber(tempArr)
    }

    const addStudentsForPlacementDrive = async () => {
        setIsAdding(true)
        const addStudentsForPlacementDriveResponse = await fetch(BASE_URL_API + "/addStudentsForPlacementDrive",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    studentsList: JSON.stringify(scannedNumber),
                    driveIdentifier: driveIdentifier,
                    registeredByIdentifier: authCtx?.userIdentifier
                })
            });

        if (!addStudentsForPlacementDriveResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addStudentsForPlacementDriveRespo = await addStudentsForPlacementDriveResponse.json()
            if (addStudentsForPlacementDriveRespo.status === "success") {
                setSentNumber(addStudentsForPlacementDriveRespo?.response)
                setScannedNumber(addStudentsForPlacementDriveRespo?.response)
                onSuccess()
            } else {
                if (addStudentsForPlacementDriveRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(addStudentsForPlacementDriveRespo)
        }
        setIsAdding(false)
    }


    console.log(scannedNumber)


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.contentContainer}>
                        <div className={styles.leftWrapper}>
                            <div className={styles.leftHeaderWrapper}>
                                Upload Students Data
                            </div>
                            <div className={styles.textareaWrapper}>
                                <textarea placeholder="Enter students phone number, each number in a new line..."
                                    onChange={(e) => setRawData(e.target.value)}
                                    value={rawData}
                                >

                                </textarea>
                            </div>
                            <div>
                                <button onClick={() => toggleAddStudents()} >Close</button>
                                <button onClick={() => readDataHandler()}>Read Data</button>
                            </div>

                        </div>

                        <div className={styles.rightWrapper}>
                            <div className={styles.tableWrapper}>
                                <div className={styles.headerWrapper}>
                                    <div className={styles.sn}>
                                        #
                                    </div>
                                    <div className={styles.phoneNumber}>
                                        Phone
                                    </div>
                                    <div className={styles.isNumberValid}>
                                        isValid
                                    </div>
                                    <div className={styles.fullName}>
                                        Full Name
                                    </div>
                                    <div className={styles.actionWrapper}>
                                        View Profile
                                    </div>
                                    <div className={styles.driveRegistrationStatus}>
                                        Drive Registration Status
                                    </div>
                                </div>
                                <div className={styles.tableBody}>
                                    {scannedNumber?.map((item, index) => (
                                        <div key={index} className={styles.eachRowWrapper}>
                                            <div className={styles.sn}>
                                                {index + 1}
                                            </div>
                                            <div className={styles.phoneNumber}>
                                                {item?.phoneNumber}
                                            </div>
                                            <div className={styles.isNumberValid}>
                                                {item?.isNumberValid === true ? 'Yes' : 'No'}
                                            </div>
                                            <div className={styles.fullName}>
                                                {item?.fullName}
                                            </div>
                                            <div className={styles.actionWrapper}>
                                                {item?.stylistIdentifier !== null ?
                                                    <a href={`/manage-users/grow/${item?.stylistIdentifier}`} target="_blank">View Profile</a>
                                                    :
                                                    <a href="/manage-users/grow" target="_blank">Create Profile</a>
                                                }
                                            </div>
                                            <div>
                                                {item?.hasRegisteredForDrive}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div>
                                {isAdding === false ?
                                    <button onClick={() => addStudentsForPlacementDrive()} >Update All!</button>
                                    :
                                    <button>Please wait...</button>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FTAddStudents