export const sanitizeRoute = (e) => {
    let p = e.trim();
    p = p.replace(/\s/g, '');
    p = p.replace(/\//g, '');
    p = p.replace(/\$/g, '');
    p = p.replace(/\?/g, '');
    p = p.replace(/\!/g, '');
    p = p.replace(/\@/g, '');
    p = p.replace(/\#/g, '');
    p = p.replace(/\%/g, '');
    p = p.replace(/\^/g, '');
    p = p.replace(/\&/g, '');
    p = p.replace(/\*/g, '');
    p = p.replace(/\(/g, '');
    p = p.replace(/\)/g, '');
    p = p.replace(/\=/g, '');
    p = p.replace(/\+/g, '');
    p = p.replace(/\`/g, '');
    p = p.replace(/\~/g, '');
    p = p.replace(/\|/g, '');
    p = p.replace(/\\/g, '');
    p = p.replace(/\[/g, '');
    p = p.replace(/\]/g, '');
    p = p.replace(/\{/g, '');
    p = p.replace(/\}/g, '');
    p = p.replace(/\:/g, '');
    p = p.replace(/\;/g, '');
    p = p.replace(/\'/g, '');
    p = p.replace(/\"/g, '');
    p = p.replace(/\./g, '');
    p = p.replace(/\,/g, '');
    p = p.replace(/\>/g, '');
    p = p.replace(/\</g, '');
    p = p.toLowerCase();
    p = getRidofDuplication(p);
    return p;
}

function getRidofDuplication(key) {
    if (key.length > 1) {
        let lKey = key.charAt(key.length - 1);
        let slKey = key.charAt(key.length - 2);
        if (lKey === "-" || lKey === "_" || lKey === "=" || lKey === "-_" || lKey === "_-" || lKey === "=_" || lKey === "_=" || lKey === "-=" || lKey === "=-" || lKey === "_-_") {
            if (lKey == slKey) {
                key = key.slice(0, -1);
            }
            return key;
        } else {
            return key;
        }
    } else {
        return key;
    }
}

export const getAge = (k) => {
    let curTime = new Date();
    curTime = curTime.toISOString();
    try {
        let recTime = new Date(k).toISOString();
        let diff = new Date(curTime) - new Date(recTime);

        let days = Math.floor(diff / (24 * 60 * 60 * 1000));
        let returningItem = '';
        returningItem = (parseInt(days / 365.4))
        return returningItem;
    } catch (error) {
        return "invalidTime"
    }
}

export const getTimeDifferenceHumanReadable = (date1, date2) => {
    // Check if both dates are valid using try-catch
    try {
        new Date(date1);
        new Date(date2);
    } catch (error) {
        return "Invalid date format. Please use YYYY-MM-DD HH:MM:SS";
    }

    const differenceInMs = Math.abs(new Date(date1) - new Date(date2));
    const differenceInSeconds = Math.round(differenceInMs / 1000);
    const minutes = Math.round(differenceInSeconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const months = Math.round(days / 30);

    if (minutes < 60) {
        return minutes === 1 ? `Within ${minutes} minute` : `Within ${minutes} minutes`;
    } else if (days < 1) {
        return hours === 1 ? `Within ${hours} hour ` : `Within ${hours} hours`;
    } else if (days < 7) {
        return days === 1 ? `Within ${days} day` : `Within ${days} days`;
    } else if (days < 30) {
        return months === 1 ? `Within ${months} month` : `Within ${months} months`;
    } else {
        // Handle future dates or large differences
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);
        if (dateObj1 > dateObj2) {
            return `${date1} is in the future`;
        } else {
            return "More than a month ago"; // For large differences
        }
    }
}

export const getHumanReadableExperienceDuration = (date1, date2) => {
    // Check if date1 is a valid date
    try {
        new Date(date1);
    } catch (error) {
        return "Invalid date format for date1. Please use YYYY-MM-DD HH:MM:SS";
    }

    let endDate;
    // Check if date2 is valid or "0000-00-00 00:00:00"
    if (date2 === "0000-00-00 00:00:00") {
        endDate = new Date();
    } else {
        try {
            endDate = new Date(date2);
        } catch (error) {
            return "Invalid date format for date2. Please use YYYY-MM-DD HH:MM:SS or provide '0000-00-00 00:00:00' to consider the current date.";
        }
    }

    const startDate = new Date(date1);

    const yearsDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthsDiff = endDate.getMonth() - startDate.getMonth();
    const daysDiff = endDate.getDate() - startDate.getDate();

    let duration = "";

    if (yearsDiff > 0) {
        duration += `${yearsDiff} ${yearsDiff === 1 ? 'year' : 'years'}`;
    }
    if (monthsDiff > 0) {
        if (duration !== "") duration += ", ";
        duration += `${monthsDiff} ${monthsDiff === 1 ? 'month' : 'months'}`;
    }
    if (daysDiff > 0) {
        if (duration !== "") duration += ", ";
        duration += `${daysDiff} ${daysDiff === 1 ? 'day' : 'days'}`;
    }

    if (duration === "") {
        return "Less than a day";
    }

    return duration;
}

export const humanReadableTimePassed = (k) => {
    let curTime = new Date();
    curTime = curTime.toISOString();
    try {
        let recTime = new Date(k).toISOString();
        let diff = new Date(curTime) - new Date(recTime);

        let days = Math.floor(diff / (24 * 60 * 60 * 1000));
        let hours = Math.floor((diff - days * (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

        // let minutes = Math.floor(diff - ((diff - days * (24 * 60 * 60 * 1000)) / ( 60*1000)) );

        let minutes = Math.floor((diff / (1000 * 60)) % 60)


        // console.log("Minutes : ",minutes);

        let returningItem = '';
        if (days < 1) {
            if (hours < 1) {
                returningItem = minutes + " mins ago";
            } else if (hours < 5) {
                returningItem = hours + " hours " + minutes + " mins ago";
            } else if (hours < 12) {
                returningItem = hours + " hours ago";
            } else if (hours < 14) {
                returningItem = " today";
            } else {
                returningItem = " yesterday";
            }
        } else if (days < 30) {
            if (days < 7) {
                returningItem = days + " days ago";
            } else if (days > 6) {
                returningItem = " around a week ago";
            } else if (days < 13) {
                returningItem = days + " days ago";
            } else if (days < 15) {
                returningItem = " 2 weeks ago";
            } else if (days > 15) {
                returningItem = " 3 weeks ago";
            } else {
                returningItem = " around a month ago";
            }
        } else if (days > 29) {
            if (days < 45) {
                returningItem = " around a month ago";
            } else if (days < 60) {
                returningItem = " one & half month ago";
            } else if (days < 75) {
                returningItem = " 2 months ago";
            } else if (days < 90) {
                returningItem = " around 3 months ago";
            } else if (days < 115) {
                returningItem = " 3 months ago";
            } else if (days < 135) {
                returningItem = " 4 months ago";
            } else if (days < 165) {
                returningItem = " 5 months ago";
            } else if (days < 195) {
                returningItem = " 6 months ago";
            } else if (days < 225) {
                returningItem = " 7 months ago";
            } else if (days < 255) {
                returningItem = " 8 months ago";
            } else if (days < 285) {
                returningItem = " 9 months ago";
            } else if (days < 315) {
                returningItem = " 10 months ago";
            } else if (days < 345) {
                returningItem = " 11 months ago";
            } else if (days < 361) {
                returningItem = " last year";
            }
        } else if (days > 360) {
            returningItem = " last year";
        } else if (days > 720) {
            returningItem = " 1 and half year ago";
        } else {
            returningItem = " 2 years ago";
        }

        return returningItem;
    } catch (error) {
        // console.log(error)
        return "InvalidTime"
    }
}

export const toDayMonthYear = (k) => {
    let date = k.split(" ")[0];
    let dd = date.split("-")[2];
    let mm = date.split("-")[1];
    let yyyy = date.split("-")[0];

    let returningArr = [dd, mm, yyyy];

    return returningArr;

}

export const monthNumToTxtSmall = (k) => {
    if (k === '01') { return 'Jan' }
    if (k === '02') { return 'Feb' }
    if (k === '03') { return 'Mar' }
    if (k === '04') { return 'Apr' }
    if (k === '05') { return 'May' }
    if (k === '06') { return 'Jun' }
    if (k === '07') { return 'Jul' }
    if (k === '08') { return 'Aug' }
    if (k === '09') { return 'Sep' }
    if (k === '10') { return 'Oct' }
    if (k === '11') { return 'Nov' }
    if (k === '12') { return 'Dec' }
}

export const capitalizeString = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getDateRange = (range) => {
    function convertDateFormat(dateString) {
        const parts = dateString.split(', '); // split the original string into date and time parts
        const dateParts = parts[0].split('/'); // split the date part into day, month, and year
        const year = dateParts[2];
        const month = dateParts[1].length === 1 ? `0${dateParts[1]}` : dateParts[1]; // add leading zero if month is single digit
        const day = dateParts[0].length === 1 ? `0${dateParts[0]}` : dateParts[0]; // add leading zero if day is single digit
        const time = parts[1];
        return `${year}-${month}-${day} ${time}`; // return the reformatted string
    }


    const now = new Date();
    let startDate, endDate;

    switch (range) {
        case "Today":
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
            break;
        case "Yesterday":
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);
            break;
        case "This Week":
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (6 - now.getDay()) + 1, 23, 59, 59);
            break;
        case "Last Week":
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 6, 0, 0, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay(), 23, 59, 59);
            break;
        case "This Month":
            startDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 1);
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);
            break;
        case "Last Month":
            startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59);
            break;
        case "This Year":
            startDate = new Date(now.getFullYear(), 0, 1, 0, 0, 1);
            endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59);
            break;
        case "Last Year":
            startDate = new Date(now.getFullYear() - 1, 0, 1, 0, 0, 1);
            endDate = new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59);
            break;
        case "Lifetime":
            startDate = new Date('1947-08-15 00:00:01');
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
            break;
        default:
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
            break;
    }

    const dateRange = {
        startDate: convertDateFormat(startDate.toLocaleString(undefined, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })),
        endDate: convertDateFormat(endDate.toLocaleString(undefined, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }))
    };

    return dateRange;
}

export const replaceSpecialCharStr = (k) => {
    let str = k.replaceAll("+", " ")
    str = str.replaceAll("%26", "&")
    str = str.replaceAll("%27", "'")
    str = str.replaceAll("%40", "@")
    str = str.replaceAll("%2C", ",")

    return str;
}

const stateLongToShort = (k) => {
    let temp = k.trim()
    temp = temp.replaceAll("Uttar Pradesh", "UP")
    temp = temp.replaceAll("Himachal Pradesh", "HP")
    temp = temp.replaceAll("Andhra Pradesh", "AP")
    temp = temp.replaceAll("Madhya Pradesh", "MP")

    return temp
}

export const parseAddressToShortFormate = (k) => {
    let arr = k?.split(",")
    let tempAdd = ''
    if (arr !== undefined) {
        if (arr.length < 4) {
            tempAdd = arr[0] + ", " + arr[1] + ", " + arr[2];
        } else if (arr.length === 4) {
            tempAdd = arr[1] + ", " + arr[2] + ", " + arr[3];
        } else if (arr.length === 5) {
            tempAdd = arr[2] + ", " + arr[3] + ", " + arr[4];
        } else if (arr.length > 5) {
            tempAdd = arr[3] + ", " + arr[4] + ", " + arr[5];
        }
        return stateLongToShort(tempAdd)
    } else {
        // console.log(arr, k)
    }
}

export const convertTimeStampToHHMM = k => {
    if (k !== '' && k !== undefined) {
        let ampm = ''
        let ts = k?.split(" ")[1]
        let hh = ts?.split(":")[0]
        if (hh > 11) {
            ampm = 'pm'
            if (hh > 12) {
                hh = hh - 12
            }
        } else {
            ampm = 'am'
        }
        let mm = ts?.split(":")[1]
        return hh + ":" + mm + " " + ampm
    } else {
        return "ITS"
    }
}

export const getDayFromDate = (dateStr) => {
    // Create a Date object from the date string.
    var date = new Date(dateStr);

    // Get the day of the week from the Date object.
    var day = date.getDay();

    // Return the day of the week as a string.
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[day];
}

export const getPincodeFromAddress = (address) => {
    const pincodeRegex = /(?:^|\D)(\d{6})(?!\d)/;
    const match = address.match(pincodeRegex);

    if (match) {
        return match[1];
    } else {
        return null;
    }
}

export const isValidMobileNumber = (mobileNumber) => {
    const mobileNumberRegex = /^[1-9][0-9]{9}$/;
    // console.log(mobileNumberRegex.test(mobileNumber))
    return mobileNumberRegex.test(mobileNumber);
}

// Array of valid top-level domains
const validTLDs = ['com', 'net', 'org', 'edu', 'gov', 'io', 'in', 'hair', 'live', 'link', 'luxury', 'makeup', 'online', 'outlook', 'mil', 'academy', 'salon', 'beauty', 'hotmail', 'ac', 'co', 'ai', 'uk', 'io', 'vo'];

// Array of invalid keywords
const invalidKeywords = ['test', 'sample', 'demo'];

// Function to validate email address
export const isValidEmail = (email) => {
    const [localPart, domain] = email.split('@');
    const tld = domain ? domain.split('.').pop().toLowerCase() : '';

    const isValidTLD = validTLDs.includes(tld);
    const hasInvalidKeyword = invalidKeywords.some(keyword =>
        email.toLowerCase().includes(keyword)
    );

    const emailRegex = /^(?!.*(?:test|sample|demo))^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return (
        emailRegex.test(email) &&
        isValidTLD &&
        !hasInvalidKeyword &&
        !invalidKeywords.some(keyword => localPart.toLowerCase().includes(keyword))
    );
}

export const minMaxAgeHandler = (minAge, maxAge, action, target) => {
    let t = '';
    if (target === "minAge") {
        t = minAge
        if (action === "inc") {
            if (t < maxAge) {
                t = t + 1
            }
        } else if (action === "dec") {
            if (t > 13) {
                t = t - 1
            }
        }
        return t
    } else if (target === "maxAge") {
        t = maxAge
        if (action === "inc") {
            if (t < 51) {
                t = t + 1
            }
        } else if (action === "dec") {
            if (t > minAge) {
                t = t - 1
            }
        }
        return t
    } else {
        return t
    }
}


export const sanitizeJobTitle = (input) => {
    // Remove leading and trailing spaces
    const trimmedInput = input;

    if (trimmedInput.length === 0) {
        return ''; // Disallow white space at the beginning
    }

    // Use a regular expression to replace non-alpha characters
    let sanitizedInput = trimmedInput.replace(/[^a-zA-Z ]+/g, '');

    // Ensure only one continuous space remains within the string
    let finalInput = sanitizedInput.replace(/\s{2,}/g, ' ');
    finalInput = finalInput.trimStart()

    return finalInput;
}


export const calculateDistance = (lat1, lng1, lat2, lng2) => {
    // Example usage
    // const distance = calculateDistance(37.7749, -122.4194, 34.0522, -118.2437);
    // console.log(`Distance: ${distance.toFixed(2)} km`);



    // Radius of the Earth in kilometers
    const earthRadius = 6371;

    // Convert latitude and longitude from degrees to radians
    const radLat1 = (lat1 * Math.PI) / 180;
    const radLng1 = (lng1 * Math.PI) / 180;
    const radLat2 = (lat2 * Math.PI) / 180;
    const radLng2 = (lng2 * Math.PI) / 180;

    // Calculate differences in coordinates
    const deltaLat = radLat2 - radLat1;
    const deltaLng = radLng2 - radLng1;

    // Haversine formula to calculate distance
    const a =
        Math.sin(deltaLat / 2) ** 2 +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(deltaLng / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in kilometers
    const distance = earthRadius * c;

    return distance;
}


export const validatePhoneNumber = number => {
    // Trim whitespace and remove all non-digit characters
    const digitsOnly = number.trim().replace(/\D/g, "");

    // Check if empty string, length not 10 or first digit less than 5
    if (!digitsOnly || digitsOnly.length !== 10 || digitsOnly[0] < 5) {
        return false;
    }

    // Return true if all conditions are met
    return true;
}



export const convertTimeSlotToReadableForm = (dateTimeString, targetLanguages = ["en", "hi"]) => {
    try {
        // Use new Date() for robust parsing and potential error catching
        const dateTime = new Date(dateTimeString);

        // Validate date object creation, returning "invalid time" if unsuccessful
        if (isNaN(dateTime.getTime())) {
            // return { [targetLanguages.join(",")]: "invalidTimeSlot" };
            return "invalidTimeSlot"
        }

        // Format the date and time according to the specified languages
        const formattedTimes = {};
        for (const lang of targetLanguages) {
            const options = {
                weekday: "long",
                year: "numeric",
                month: lang === "hi" ? "long" : "short", // Full month name for English, short for Hindi
                day: "2-digit",
                hour12: false, // We'll handle the meridiem ourselves
            };

            // Format date separately
            const formattedDate = dateTime.toLocaleDateString(lang, options);

            // Add meridiem indicator and time
            if (lang === "en") {
                const formattedTime = dateTime.toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' });
                formattedTimes[lang] = `${formattedDate}, ${formattedTime}`;
            } else if (lang === "hi") {
                // Calculate adjustedHour within the Hindi block for correct scoping
                const hours = dateTime.getHours();
                let adjustedHour; // Declare within the block
                let meridiem;

                if (hours > 4 && hours < 12) {
                    meridiem = "सुबह";
                    adjustedHour = hours % 12; // 12-hour format
                } else if (hours == 12) {
                    meridiem = "दोपहर";
                    adjustedHour = hours;
                } else if (hours >= 12 && hours < 16) {
                    meridiem = "दोपहर";
                    adjustedHour = hours - 12;
                } else if (hours >= 16 && hours < 20) {
                    meridiem = "शाम";
                    adjustedHour = hours - 12;
                } else if (hours >= 20) {
                    meridiem = "रात";
                    adjustedHour = hours - 12;
                } else {
                    meridiem = "रात";
                    adjustedHour = hours;
                }

                // Add leading zero for hours in Hindi
                adjustedHour = adjustedHour.toString().padStart(2, '0');

                const formattedMinute = ("0" + dateTime.getMinutes()).slice(-2); // Leading zero for minutes
                formattedTimes[lang] = `${formattedDate}, ${meridiem} ${adjustedHour}:${formattedMinute} बजे`;
            }
        }

        return formattedTimes;
    } catch (error) {
        // Handle generic error and return "invalid time"
        console.error("Error converting time:", error);
        // return { [targetLanguages.join(",")]: "invalidTimeSlot" };
        return "invalidTimeSlot"
    }
}




// export const convertToArray = (p) => {
//     return Array.isArray(p) ? p : (p !== undefined && p !== null && p !== "") ? [p] : [];
// }

export const convertToArray = (p) => {
    if (Array.isArray(p)) return p;

    if (typeof p === 'string') {
        try {
            const parsed = JSON.parse(p);
            return Array.isArray(parsed) ? parsed : [parsed];
        } catch (e) {
            return p !== "" ? [p] : [];
        }
    }

    return (p !== undefined && p !== null) ? [p] : [];
};

export const numberToWords = (inputNumber) => {
    let numberString = inputNumber.toString().replace(/[,.]/g, ''); // Remove commas and dots

    if (isNaN(numberString)) {
        return 'Invalid number';
    }

    let number = Number(numberString);

    if (number === 0) {
        return 'zero';
    }

    const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    function convertLessThanOneThousand(num) {
        let current = '';
        let hundreds = Math.floor(num / 100);
        let remainder = num % 100;

        if (hundreds > 0) {
            current += ones[hundreds] + ' hundred';
        }

        if (remainder > 0) {
            if (current) {
                current += ' ';
            }
            if (remainder < 10) {
                current += ones[remainder];
            } else if (remainder < 20) {
                current += teens[remainder - 10];
            } else {
                current += tens[Math.floor(remainder / 10)];
                if (remainder % 10 > 0) {
                    current += '-' + ones[remainder % 10]; // Hyphen for values like 21, 42
                }
            }
        }

        return current.trim();
    }

    let result = '';
    let chunk;
    let chunkIndex = 0;

    const units = ['', ' thousand', ' million', ' billion', ' trillion', ' quadrillion', ' quintillion', ' sextillion'];

    while (number > 0) {
        chunk = number % 1000;
        if (chunk !== 0) {
            const chunkStr = convertLessThanOneThousand(chunk);
            result = chunkStr + (units[chunkIndex] ? ' ' + units[chunkIndex] : '') + (result ? ' ' + result : '');
        }
        number = Math.floor(number / 1000);
        chunkIndex++;
    }

    return result.trim();
};

// Example usage:
// console.log(addPeriodToDate('2023-09-29', 'days', 10));  // Output: New date 10 days later
// console.log(addPeriodToDate('2023-09-29', 'months', 2)); // Output: New date 2 months later
// console.log(addPeriodToDate('2023-09-29', 'years', 1));  // Output: New date 1 year later
export const addPeriodToDate = (inputDate, periodType, periodValue) => {
    // console.log("Adding period:", { inputDate, periodType, periodValue }); // Log the parameters

    let date = new Date(inputDate);


    // Validate if the input date is valid
    if (isNaN(date.getTime())) {
        console.error('Invalid Date:', date); // Log if date is invalid
        return 'Invalid date format';
    }
    const value = parseInt(periodValue, 10);

    // Add the specified period
    switch (periodType.toLowerCase()) {
        case 'days':
            date.setDate(date.getDate() + value);
            break;
        case 'months':
            date.setMonth(date.getMonth() + value);
            break;
        case 'years':
            date.setFullYear(date.getFullYear() + value);
            break;
        default:
            console.error('Invalid period type:', periodType); // Log if period type is invalid
            return 'Invalid period type. Use "days", "months", or "years".';
    }

    // Return the date in 'YYYY-MM-DD' format
    const formattedDate = date.toISOString().split('T')[0];
    // console.log("New Date:", formattedDate); // Log the new date
    return formattedDate;
};



export const hasExpired = (dateStr) => {
    // Parse the input date string in the format 'YYYY-MM-DD HH:mm:ss'
    const [datePart, timePart] = dateStr.split(" ");
    const [year, month, day] = datePart.split("-").map(Number);
    const [hour, minute, second] = timePart.split(":").map(Number);

    // Construct Date object for the input date
    const inputDate = new Date(year, month - 1, day, hour, minute, second);

    // Get the current date and time
    const now = new Date();

    // Return true if the input date is in the past, otherwise false
    return inputDate < now;

    // Example usage:
    // console.log(hasExpired('2024-10-31 17:05:04')); // Adjust date to test past and future cases
};


export const checkExpiry = (dateStr) => {
    // Parse the input date string in the format 'YYYY-MM-DD HH:mm:ss'
    const [datePart, timePart] = dateStr.split(" ");
    const [year, month, day] = datePart.split("-").map(Number);
    const [hour, minute, second] = timePart.split(":").map(Number);

    // Construct Date object for the input date
    const inputDate = new Date(year, month - 1, day, hour, minute, second);

    // Get current date and time
    const today = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = inputDate - today;

    // Convert difference from milliseconds to days (consider partial days)
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Check if input date is in the future or past, and handle edge cases
    if (daysDifference > 0) {
        return `in ${daysDifference} day${daysDifference === 1 ? '' : 's'}`;
    } else if (daysDifference < 0) {
        return `${Math.abs(daysDifference)} day${Math.abs(daysDifference) === 1 ? '' : 's'} ago`;
    } else {
        const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        if (hoursDifference > 0) {
            return `in ${hoursDifference} hour${hoursDifference === 1 ? '' : 's'}`;
        } else if (hoursDifference < 0) {
            return `${Math.abs(hoursDifference)} hour${Math.abs(hoursDifference) === 1 ? '' : 's'} ago`;
        } else if (minutesDifference > 0) {
            return `in ${minutesDifference} minute${minutesDifference === 1 ? '' : 's'}`;
        } else if (minutesDifference < 0) {
            return `${Math.abs(minutesDifference)} minute${Math.abs(minutesDifference) === 1 ? '' : 's'} ago`;
        } else {
            return "just now";
        }
    }
    // Example usage:
    //   console.log(checkExpiry('2024-10-31 17:05:04')); // Adjust to test past, present, and future cases
};

