import React, { useEffect, useState } from 'react'
import styles from './ViewNotification.module.css'

const ViewNotification = (props) => {
    const { onClose } = props
    const [yourSalary, setYourSalary] = useState(0)
    const [yourPlacements, setYourPlacements] = useState(0)
    const [contri, setContri] = useState({
        revenue: 0,
        pnl: 0,
        totalSalary: 0
    })

    function calculateTotalSalary(p, targetedPlacements, achievedPlacements) {
        const baseSalary = parseFloat(p).toFixed(0)
        const placementValue = 2500;
        const revenue = achievedPlacements * placementValue;

        // Calculate incentive amount based on achieved placements
        const incentiveRate = achievedPlacements > 30 ? 0.25 : (achievedPlacements > 15 ? 0.10 : 0);
        const incentiveAmount = revenue * incentiveRate;

        // Calculate shortfall penalty
        const shortfallPenalty = Math.max(0, targetedPlacements - achievedPlacements) * 0.02 * baseSalary;

        // Calculate total salary including incentives and minus penalties
        const totalSalary = (parseFloat(baseSalary) + parseFloat(incentiveAmount)) - shortfallPenalty;
        // console.log("baseSalary", baseSalary)
        // console.log("incentiveAmount", incentiveAmount)
        // console.log("shortfallPenalty", shortfallPenalty)
        // console.log("totalSalary", totalSalary)
        setContri({
            revenue: revenue,
            pnl: (revenue - totalSalary),
            totalSalary: totalSalary
        })
    }

    useEffect(() => {
        calculateTotalSalary(yourSalary, 25, yourPlacements)
    }, [yourSalary, yourPlacements])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        Circular Regarding New Policy Implementation: Daily Paid Transaction Targets and Incentive Structure
                    </div>
                    <div className={styles.mainBody}>
                        <div>
                            Date: 5th November 2024
                        </div>
                        <div className={styles.subject}>
                        </div>
                        <p>
                            Dear Team,
                        </p>
                        <p>
                            I hope this message finds you well.
                        </p>
                        As part of our continuous efforts to drive performance and align individual contributions with organizational goals, we are introducing a new policy regarding daily paid transaction targets, effective immediately. However, we have made some important considerations to ensure that this policy is both fair and supportive.
                        <h5>Daily Paid Transaction Targets</h5>
                        Starting today, each Sales Executive is required to achieve at least one paid transaction per day. This target is crucial for maintaining our competitive edge and ensuring that we meet our collective objectives.
                        <h5>Grace Period for Adjustment</h5>
                        We understand that new policies can take time to adapt to. Therefore, we are implementing a one-week grace period where no penalties will be applied for missing the daily target. This period will allow you to adjust to the new expectations without immediate consequences.
                        <h5>Salary Deduction for Non-Compliance</h5>
                        After the grace period, a 2% deduction from your monthly salary will be applied for each day the target is not met. However, we recognize that certain days may present unique challenges. If you encounter such challenges, please communicate with your manager so we can assess the situation fairly.
                        <h5>Incentive Structure</h5>
                        <p>
                            We are also pleased to announce a new incentive plan:
                        </p>
                        <ul>
                            <li>
                                Monthly Target Incentive: If a Sales Executive achieves more than 25 paid transactions in a month, they will receive upto 25% incentive on top of their full salary.
                            </li>
                            <li>
                                No Incentive Below 15 Transactions: Please note that no incentive will be awarded for less than 15 transactions in a given month.
                            </li>
                        </ul>
                        <div className={styles.tableWrapper}>
                            <table>
                                <tr>
                                    <td>
                                        Number of Placements
                                    </td>
                                    <td>
                                        Incentive
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        15
                                    </td>
                                    <td>
                                        0%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        15 - 30
                                    </td>
                                    <td>
                                        10%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    > 30
                                    </td>
                                    <td>
                                        25%
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className={styles.scWrapper}>
                            <div className={styles.scTitle}>
                                Salary Calculator
                                <div className={styles.smallTxt}>You can estimate your salary</div>
                            </div>
                            <div className={styles.scBody}>
                                <div className={styles.inputFieldWrapper}>
                                    <div>
                                        Enter your fixed monthly salary
                                        <input placeholder="Enter your salary..." value={yourSalary} onChange={(e) => setYourSalary(e.target.value)} />
                                    </div>
                                    <div>
                                        Enter your placements in this month
                                        <input placeholder="Enter your placements done..." value={yourPlacements} onChange={(e) => setYourPlacements(e.target.value)} type="number" />
                                    </div>
                                </div>
                                <div className={`${styles.inputFieldWrapper} ${styles.dark}`}>
                                    <div>
                                        Estimated Revenue
                                        <input placeholder="Enter your salary..." value={contri?.revenue} readOnly />
                                    </div>
                                    <div>
                                        Estimated Salary
                                        <input placeholder="Enter your placements done..." value={parseFloat(contri?.totalSalary).toFixed(1)} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5>Support for Performance Improvement</h5>
                        We are committed to providing additional training and support for those who find the daily target challenging. Our goal is to help every team member succeed, and we encourage you to reach out if you need assistance in meeting your targets.
                        <h5>Implementation Timeline and Feedback</h5>
                        This policy is effective immediately, with the grace period starting today. We will be conducting regular reviews to assess the policy’s effectiveness and gather your feedback. Should you have any queries or concerns, you are encouraged to reach out within the next 48 hours. If no response is received, the policy will be automatically applicable to all relevant individuals without requiring further acknowledgment.
                        <p>We believe that this policy will foster a more results-driven environment while providing clear rewards for consistent performance. Your dedication and commitment are vital to our success, and we are confident that these changes will help us all achieve our goals.</p>

                        <div className={styles.signature}>
                            <div>
                                Thank you for your understanding and cooperation.
                            </div>
                            <div>
                                Best regards,
                            </div>
                            <div>
                                Navneet Chandan
                            </div>
                        </div>
                    </div>
                    <div className={styles.mainFooter}
                        onClick={() => onClose()}
                    >
                        Close
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ViewNotification