import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import FTAddStudents from '../../Components/FastTrack/FTAddStudents'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './FTEachPlacementDrive.module.css'

const FTEachPlacementDrive = () => {
    const authCtx = useContext(AuthContext)
    const { driveIdentifier } = useParams()
    const [addStudentsOpen, setAddStudentsOpen] = useState(false)
    const toggleAddStudents = () => setAddStudentsOpen(false)
    const [allStudentRecords, setAllStudentRecords] = useState([])
    const [isCollectingRecords, setIsCollectingRecords] = useState(false)

    const getStudentsForPlacementDrive = async () => {
        setIsCollectingRecords(true)
        const getStudentsForPlacementDriveResponse = await fetch(BASE_URL_API + "/getStudentsForPlacementDrive",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    driveIdentifier: driveIdentifier
                })
            });

        if (!getStudentsForPlacementDriveResponse.ok) {
            console.log("Something went wrong : Server error")
        } else {
            const getStudentsForPlacementDriveRespo = await getStudentsForPlacementDriveResponse.json()
            if (getStudentsForPlacementDriveRespo.status === "success") {
                setAllStudentRecords(getStudentsForPlacementDriveRespo.response)
            } else {
                if (getStudentsForPlacementDriveRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllStudentRecords([])
                }
            }
            console.log(getStudentsForPlacementDriveRespo)
        }
        setIsCollectingRecords(false)
    }

    useEffect(() => {
        getStudentsForPlacementDrive()
    }, [driveIdentifier])


    return (
        <div className={styles.mainWrapper}>
            {addStudentsOpen && <FTAddStudents driveIdentifier={driveIdentifier} isOpen={addStudentsOpen} onSuccess={getStudentsForPlacementDrive} toggleAddStudents={toggleAddStudents} />}
            <div className={styles.mainContainer}>
                <div className={styles.mainHeaderWrapper}>
                    <div>
                        FTEachPlacementDrive
                        {driveIdentifier}
                        Drive Details
                    </div>
                    <div>
                        <button onClick={() => setAddStudentsOpen(true)} >Add Students</button>
                    </div>

                </div>
                <div className={styles.mainBodyWrapper}>
                    <div>
                        <div className={styles.navigationsWrapper}>
                            <div className={styles.eachRow}>
                                <div className={styles.stylistName}>Details</div>
                                <div className={styles.phoneNumber}>Reports</div>
                                <div className={styles.currentLocation}>Students</div>
                                <div className={styles.applicationData}>Applied Data</div>
                            </div>
                        </div>

                        <div className={styles.dataTableWrapper}>
                            {isCollectingRecords === true ?
                                <div className={styles.atCenter}>
                                    Please wait, collecting records!
                                </div>
                                :
                                allStudentRecords?.length < 1 ?
                                    <div className={styles.atCenter}>
                                        You haven't added any students so far!
                                    </div>
                                    :
                                    <>
                                        {allStudentRecords?.map((item, index) => (
                                            <div key={index} className={styles.eachRow}>
                                                <div className={styles.stylistName}>{item?.stylist_name}</div>
                                                <div className={styles.phoneNumber}>
                                                    {item?.contact_number}
                                                </div>
                                                <div className={styles.currentLocation}>
                                                    {item?.currentLocation}
                                                </div>
                                                <div className={styles.applicationData}>
                                                    <div>
                                                        {/* {item?.applicationData} */}
                                                    </div>
                                                    <div>
                                                        {JSON.parse(item?.applicationData)?.length > 0 ?
                                                            ` Applied for ${JSON.parse(item?.applicationData)?.length} place` : ``}
                                                    </div>
                                                    <div>
                                                        <button>View Application Status</button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FTEachPlacementDrive