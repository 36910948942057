import React, { useContext, useEffect, useState } from 'react'
import { BiMessageDetail } from 'react-icons/bi';
import { BsWhatsapp } from 'react-icons/bs';
import { FaEye, FaHistory } from 'react-icons/fa';
import { IoIosCreate } from 'react-icons/io';
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { MdCalendarToday, MdCancel, MdDone, MdDoneAll, MdSendToMobile } from 'react-icons/md';
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AllStoreConversations from '../../Components/Conversations/AllStoreConversations';
import CandidateApplicationRecords from '../../Components/CreateApplications/CandidateApplicationRecords';
import ApplicationNotes from '../../Components/ManageApplications/ApplicationNotes';
import EachApplicationStatus from '../../Components/ManageApplications/EachApplicationStatus';
import EachApplicationStatusTimeline from '../../Components/ManageApplications/EachApplicationStatusTimeline';
import HireCandidate from '../../Components/ManageApplications/HireCandidate';
import RejectApplication from '../../Components/ManageApplications/RejectApplication';
import SendInterviewDetails from '../../Components/ManageApplications/SendInterviewDetails';
import SlotSelector from '../../Components/ManageApplications/SlotSelector';
import SlotSelectorWrapper from '../../Components/ManageApplications/SlotSelectorWrapper';
import { getExecutiveFullName } from '../../Helper/executivesData';
import { humanReadableTimePassed } from '../../Helper/helpers';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from './ManageIndividualApplications.module.css'

const ManageIndividualApplications = () => {
    const { applicationIdentifier } = useParams();
    const [applicationData, setApplicationData] = useState([])
    const [slotWrapperStatus, setSlotWrapperStatus] = useState(false)
    const [timelineStatus, setTimelineStatus] = useState([])
    const [isLoadingTimelineStatus, setIsLoadingTimelineStatus] = useState(false)
    const [rejectionTabOpen, setRejectionTabOpen] = useState(false)
    const [isHiringTabActive, setIsHiringTabActive] = useState(false)
    const [isDetailSendingActive, setIsDetailSendingActive] = useState(false)
    const authCtx = useContext(AuthContext)
    const [isCandidateHistoryOpen, setIsCandidateHistoryOpen] = useState(false)
    const onClosingCandidateHistoryHolder = () => setIsCandidateHistoryOpen(false)

    const [isScHolderOpen, setIsScHolderOpen] = useState(false)
    const onClosingScHolder = () => setIsScHolderOpen(false)

    const slotWrapperToggler = () => { setSlotWrapperStatus(false) }
    const getApplicationDetailsForManage = async () => {
        const getApplicationDetailsForManageResponse = await fetch(BASE_URL_API + "/getApplicationDetailsForManage",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationIdentifier: applicationIdentifier
                })
            });

        if (!getApplicationDetailsForManageResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getApplicationDetailsForManageRespo = await getApplicationDetailsForManageResponse.json()
            if (getApplicationDetailsForManageRespo.status === "success") {
                setApplicationData(getApplicationDetailsForManageRespo.response)
            } else {
                if (getApplicationDetailsForManageRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
        }
    }

    console.log("application data", applicationData)

    const getApplicationStatus = async () => {
        setIsLoadingTimelineStatus(true);
        const getApplicationStatusResponse = await fetch(BASE_URL_API + "/getApplicationStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationId: applicationIdentifier
                })
            });
        if (!getApplicationStatusResponse.ok) {
            console.log("Internal Server Error : something went wrong!");
        } else {
            const getApplicationStatusRespo = await getApplicationStatusResponse.json();
            if (getApplicationStatusRespo.status === "success") {
                setTimelineStatus(getApplicationStatusRespo.response);

            } else {
                if (getApplicationStatusRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                } else {
                    setTimelineStatus([])
                }
            }
        }
        setIsLoadingTimelineStatus(false)
    }

    useEffect(() => {
        getApplicationDetailsForManage().then(
            () => getApplicationStatus()
        )
    }, [applicationIdentifier])

    const rejectApplicationInitiator = () => { setRejectionTabOpen(true) }
    const rejectApplicationHandler = () => setRejectionTabOpen(false)

    const hireCandidateInitiator = () => setIsHiringTabActive(true)
    const hireCandidateHandler = () => setIsHiringTabActive(false)

    const sendInterviewDetailsInitiator = () => setIsDetailSendingActive(true)
    const sendInterviewDetailsHandler = () => setIsDetailSendingActive(false)

    function checkApplicationStatus(applicationData, targetStatuses) {
        // Validate input types
        const isArray = Array.isArray(applicationData) && Array.isArray(targetStatuses);
        if (!isArray) {
            throw new TypeError('Invalid input: applicationData and targetStatuses must be arrays.');
        }

        // Convert targetStatuses to a lowercase array for case-insensitive matching
        const lowercasedTargetStatuses = targetStatuses.map(status => status.toLowerCase());

        // Extract applicationStatuses into a temporary array
        const applicationStatuses = applicationData?.map(app => app.applicationStatus);

        // Check if any application in the data matches the target statuses
        return applicationStatuses.some(status => {
            if (typeof status !== 'string') {
                console.warn('Ignoring non-string applicationStatus:', status);
                return false; // Treat non-string statuses as not matching
            }
            return lowercasedTargetStatuses.includes(status.toLowerCase());
        });
    }


    // console.log(applicationData)
    console.log("timelineStatus : ", timelineStatus)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftColWrapper}>
                {isScHolderOpen && <AllStoreConversations
                    isOpen={isScHolderOpen}
                    storeIdentifier={applicationData?.store_id}
                    onClose={onClosingScHolder}
                    jobIdentifier={applicationData?.job_id}
                />}
                {isCandidateHistoryOpen && 
                    <CandidateApplicationRecords isOpen={isCandidateHistoryOpen} onClose={() => onClosingCandidateHistoryHolder()} stylistIdentifier={applicationData?.stylist_ID} />
                }
                <div>
                    <button style={{ backgroundColor: '#A89BF1', border: 'none', borderRadius: '4px', height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        title="Get Candidate Past History"
                        onClick={() => setIsCandidateHistoryOpen(true)}
                    >
                        <FaHistory size={20} color="white" />
                    </button>
                </div>

                <Link style={{ backgroundColor: '#A89BF1', border: 'none', borderRadius: '4px', height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    to={`/manage-job/${applicationData?.job_id}`}
                    target="_blank"
                    title="View Job"
                >
                    <FaEye size={20} color="white" />
                </Link>
                <Link style={{ backgroundColor: '#A89BF1', border: 'none', borderRadius: '4px', height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    to={`/create-applications/${applicationData?.job_id}`}
                    target="_blank"
                    title="Create applications"
                >
                    <IoIosCreate size={20} color="white" />
                </Link>
                <button style={{ backgroundColor: '#A89BF1', border: 'none', borderRadius: '4px', height: '40px', width: '40px' }}
                    onClick={() => setIsScHolderOpen(true)}
                    title="Open all conversation related to this store"
                >
                    <IoChatbubbleEllipsesSharp size={20} color="white" />
                </button>

            </div>
            <div className={styles.rightColWrapper}>
                <ToastContainer />
                <SendInterviewDetails
                    isOpen={isDetailSendingActive}
                    applicationIdentifier={applicationIdentifier}
                    sendInterviewDetailsHandler={sendInterviewDetailsHandler}
                />
                <div className={styles.mainHeaderWrapper}>
                    <div className={styles.leftHeaderWrapper}>
                        <div className={styles.candidateWrapper}>
                            <div className={styles.stylistName}>
                                {applicationData?.stylist_name}
                                <span className={styles.age}>
                                    {applicationData?.applicantAge} years old,
                                </span>
                                <span className={styles.gender}>
                                    {applicationData?.applicantGender}
                                </span>
                            </div>

                            <div className={styles.candidatePhone}>
                                {applicationData?.stylist_contact}
                            </div>
                            <div className={styles.viewProfile}>
                                <a href={"/manage-users/grow/" + applicationData?.stylist_ID} target="_blank">
                                    View Profile
                                </a>

                            </div>
                        </div>
                        <div className={styles.companyWrapper}>
                            {applicationData?.company_name}
                            <div className={styles.storeAddress}>
                                {applicationData?.storeAddress}
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightHeaderWrapper}>
                        <span className={styles.daysAgo}>
                            App. created {humanReadableTimePassed(applicationData?.applied_on)} by {getExecutiveFullName(applicationData?.applicationCreatingExecutiveIdentifier)}
                        </span>
                    </div>
                </div>
                <div className={styles.mainContentWrapper}>

                    <div className={styles.applicationStatusWrapper}>
                        <div className={styles.appStatusContainer}>
                            <EachApplicationStatus
                                applicationIdentifier={applicationIdentifier}
                                timelineStatus={timelineStatus}
                                getApplicationStatus={getApplicationStatus}
                            />
                        </div>

                        <div className={styles.appActionContainer}>
                            {checkApplicationStatus(timelineStatus, ["HIRED", "REJECTED"]) === false &&
                                <div className={styles.actionBtns}>
                                    <button onClick={() => hireCandidateInitiator()} title="Hire Candidate!" className={styles.hireAppBtn}>
                                        <MdDone size={25} color="white" />
                                    </button>
                                    <button onClick={() => rejectApplicationInitiator()} title="Reject Application" className={styles.rejectAppBtn}>
                                        <MdCancel size={25} color="white" />
                                    </button>

                                    <button onClick={() => setSlotWrapperStatus(true)} title="Schedule Interview" className={styles.scheduleBtn}>
                                        <MdCalendarToday size={20} color="white" />
                                    </button>

                                    <button onClick={() => sendInterviewDetailsInitiator(true)} title="Send Interview Details" className={styles.sendDetailsBtn}>
                                        <BiMessageDetail size={20} color="white" />
                                    </button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className={styles.contentColumnContainer}>

                        <div className={styles.leftContentWrapper}>
                            system
                        </div>

                        <div className={styles.rightContentWrapper}>
                            <ApplicationNotes applicationIdentifier={applicationIdentifier} />
                        </div>
                    </div>




                    <SlotSelectorWrapper
                        isOpen={slotWrapperStatus}
                        slotWrapperToggler={slotWrapperToggler}
                        slotFor="INTERVIEW_SLOT_BOOKED"
                        applicationIdentifier={applicationIdentifier}
                        getApplicationStatus={getApplicationStatus}
                    />

                    <HireCandidate
                        isOpen={isHiringTabActive}
                        applicationIdentifier={applicationIdentifier}
                        hireCandidateHandler={hireCandidateHandler}
                        getApplicationStatus={getApplicationStatus}
                    />

                    <RejectApplication
                        isOpen={rejectionTabOpen}
                        rejectApplicationHandler={rejectApplicationHandler}
                        applicationIdentifier={applicationIdentifier}
                        getApplicationStatus={getApplicationStatus}
                    />


                </div>
            </div>
            {/* <EachApplicationStatusTimeline
                selectedApplication={applicationIdentifier}

            /> */}
        </div>
    )
}

export default ManageIndividualApplications