import React, { useContext, useEffect, useState } from 'react'
import styles from './ViewAllContracts.module.css'
import { Link } from 'react-router-dom'
import { MdDelete, MdDeleteForever, MdLogout, MdSearch } from "react-icons/md";
import AuthContext from '../../store/auth-context'
import { BASE_URL_API } from '../../References/urls';
import { checkExpiry, hasExpired } from '../../Helper/helpers';
import { toast, ToastContainer } from 'react-toastify';

const ViewAllContracts = () => {
    const authCtx = useContext(AuthContext)
    const [allAgreements, setAllAgreements] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchKey, setSearchKey] = useState('')

    const getAllAgreements = async () => {
        const getAllAgreementsResponse = await fetch(BASE_URL_API + "/getAllAgreements",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllAgreementsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllAgreementsRespo = await getAllAgreementsResponse.json()
            if (getAllAgreementsRespo.status === "success") {
                setAllAgreements(getAllAgreementsRespo.response)
                setFilteredData(getAllAgreementsRespo.response)
            } else {
                if (getAllAgreementsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setFilteredData([])
                    console.log(getAllAgreementsRespo)
                }
            }
        }
    }

    useEffect(() => {
        getAllAgreements()
    }, [])

    const deleteAgreement = async (k) => {
        if (window.confirm(`Are you sure, you want to permanently delete this agreeemnt?`)) {
            if (k?.agreementSigned === "1") {
                alert(`Dude, no, you can't delete a signed agreement!`)
            } else {
                if (authCtx.userIdentifier !== "999964N8406") {
                    alert(`Dude, you don't have the right to delete an agreement!`)
                } else {
                    const deleteAgreementResponse = await fetch(BASE_URL_API + "/deleteAgreement",
                        {
                            method: "POST",
                            body: JSON.stringify({
                                token: authCtx.token,
                                agreementIdentifier: k.agreementIdentifier,
                                deletedBy: authCtx.userIdentifier
                            })
                        });

                    if (!deleteAgreementResponse.ok) {
                        console.log(`Something went wrong : Server Error`)
                    } else {
                        const deleteAgreementRespo = await deleteAgreementResponse.json()
                        if (deleteAgreementRespo.status === 'success') {
                            toast(`Successfully deleted the agreement!`)
                            getAllAgreements()
                        } else {
                            toast(`Could not delete the agreement`)
                        }
                        console.log(deleteAgreementRespo)
                    }
                }
            }
        }
    }

    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterData(allAgreements, searchKey)
    }, [searchKey])
    

    return (
        <div className={styles.mainContainer}>
            <div className={styles.header}>

                <div className={styles.headerNavigation}>
                    <Link to="">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                    <Link to="/tasks-router">
                        <div className={styles.eachHeaderNavigation}>
                            Tasks
                        </div>
                    </Link>
                    <Link to="/training">
                        <div className={styles.eachHeaderNavigation}>
                            Trainings
                        </div>
                    </Link>
                </div>
                <div className={styles.headerNavigationRight}>
                    <Link to="/global-search" className={styles.gsIcon}>
                        <MdSearch size={26} />
                    </Link>
                    <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                        <MdLogout />
                    </button>
                </div>
            </div>
            <ToastContainer />
            <div className={styles.optionContainer}>
                <div className={styles.filterWrapper}>
                    <div>View All Contracts </div>
                    <div>
                        <div>
                            <input placeholder="Search for anything..." value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeaderWrapper}>
                        <div className={styles.sn}>#</div>
                        <div className={styles.companyName} >Company Name</div>
                        <div className={styles.sharedOn}>Shared On</div>
                        <div className={styles.currentStatus}>Status</div>
                        <div className={styles.validTill}>Expiry Date</div>
                        <div className={styles.action}>Action</div>
                    </div>
                    <div className={styles.tableBodyWrapper}>
                        {filteredData?.map((item, index) => (
                            <div key={index} className={styles.eachAgreementRow}>
                                <div className={styles.sn}>{index + 1}.</div>
                                <div className={styles.companyName} >
                                    {item?.secondPartyName}
                                    <div className={styles.secondPartyAddress}>
                                        {item?.secondPartyAddress}
                                    </div>
                                </div>
                                <div className={styles.sharedOn}>
                                    <div className={styles.sharedWithTxt}>
                                    {item?.sharedWithClient==="1" ? `Shared on ${item?.sharedWithClientOn?.substr(0, 10)}` : `Haven't shared!`}
                                    </div>
                                </div>
                                <div className={styles.currentStatus}>
                                    {item?.agreementSigned === "1" ? `signed` : `not-signed`}
                                </div>
                                <div className={styles.validTill}>
                                    {hasExpired(item?.contractValidTill) === true ? <div className={styles.expired}>Expired {checkExpiry(item?.contractValidTill)}</div> : <div className={styles.expiring}>Expiring {checkExpiry(item?.contractValidTill)}</div>}
                                </div>
                                <div className={styles.action}>
                                    <Link to={`/manage-service-agreement/${item?.agreementIdentifier}`} >
                                        View
                                    </Link>
                                    <button className={styles.deleteAgreement} onClick={() => deleteAgreement(item)}>
                                        <MdDeleteForever size={20} />
                                    </button>

                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ViewAllContracts